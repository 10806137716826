import React from "react";
import { Navigate, Route } from "react-router-dom";
import { useAuthContext } from "hooks/useAuthContext";
import AdminDashboard from "pages/Dashboard/Admin";
import DevicesList from "pages/Dashboard/Admin/Devices/DevicesList";
import NewDevice from "pages/Dashboard/Admin/Devices/NewDevice";
import DeviceEdit from "pages/Dashboard/Admin/Devices/DeviceEdit";
import DeviceInfo from "pages/Dashboard/Admin/Devices/DeviceInfo";
import DeviceConfig from "pages/Dashboard/Admin/Devices/DeviceConfig";
import BoxDeviceConfig from "pages/Dashboard/Admin/Devices/BoxDeviceConfig";
import BoxEditConfig from "pages/Dashboard/Admin/Devices/BoxEditConfig";
import ProductsList from "pages/Dashboard/Admin/Products/ProductsList";
import NewProduct from "pages/Dashboard/Admin/Products/NewProduct";
import ProductEdit from "pages/Dashboard/Admin/Products/ProductEdit";
import ProductQrCode from "pages/Dashboard/Admin/Products/ProductQrCode";
import OrdersList from "pages/Dashboard/Admin/Orders/OrdersList";
import OrderInfo from "pages/Dashboard/Admin/Orders/OrderInfo";
import UsersList from "pages/Dashboard/Admin/Users/UsersList";
import UsersEdit from "pages/Dashboard/Admin/Users/UsersEdit";
import ShopsList from "pages/Dashboard/Admin/Shops/ShopsList";
import NewShop from "pages/Dashboard/Admin/Shops/NewShop";
import ShopEdit from "pages/Dashboard/Admin/Shops/ShopEdit";
import DeviceDestinyConfig from "pages/Dashboard/Admin/Devices/DeviceDestinyConfig";
import InformationList from "pages/Dashboard/Admin/Information/InformationList";
import VendingList from "pages/Dashboard/Admin/Vending/VendingList";
import NewVendingOffer from "pages/Dashboard/Admin/Vending/NewVendingOffer";
import EditCoffeeData from "pages/Dashboard/Admin/Vending/EditCoffeeData";
import VendingOrdersList from "pages/Dashboard/Admin/VendingOrders/VendingOrdersList";
import VendingOrderInfo from "pages/Dashboard/Admin/VendingOrders/VendingOrderInfo";
import ChangeOrderStatus from "pages/Dashboard/Admin/Orders/ChangeOrderStatus";
import CategoryEdit from "pages/Dashboard/Admin/Products/Category/CategoryEdit";
import DeviceThermoConfig from "pages/Dashboard/Admin/Devices/DeviceThermoConfig";
import VouchersList from "pages/Dashboard/Admin/Vouchers/VouchersList";
import VoucherEdit from "pages/Dashboard/Admin/Vouchers/VoucherEdit";
import NewVoucher from "pages/Dashboard/Admin/Vouchers/NewVoucher";
import ChangeOrdersStatus from "pages/Dashboard/Admin/Orders/ChangeOrdersStatus";
import VendingSetDiscount from "pages/Dashboard/Admin/Vending/VendingSetDiscount";
import DeleteSelectedProducts from "pages/Dashboard/Admin/Products/DeleteSelectedProducts";

const AdminRoutes = () => {
	const { user } = useAuthContext();
	const isGlobal = user.supplier === "GLOBAL";

	return (
		<Route path="dashboard" element={<AdminDashboard />}>
			<Route index element={<Navigate to={isGlobal ? "devices" : "orders"} />} />

			<Route path="orders" element={<OrdersList />}>
				<Route path="change-status" element={<ChangeOrdersStatus />}></Route>
			</Route>
			<Route path="orders/:id" element={<OrderInfo />}>
				<Route path="change-status" element={<ChangeOrderStatus />}></Route>
			</Route>

			<Route path="vending-orders" element={<VendingOrdersList />}></Route>
			<Route path="vending-orders/:id" element={<VendingOrderInfo />}></Route>

			<Route path="vending" element={<VendingList />}>
				<Route path="create" element={<NewVendingOffer />} />
				<Route path=":offerId/set-discount" element={<VendingSetDiscount />} />
				{isGlobal && <Route path=":id/coffee-edit" element={<EditCoffeeData />} />}
			</Route>

			<Route path="devices" element={<DevicesList />}>
				{isGlobal && <Route path="create" element={<NewDevice />} />}
			</Route>

			<Route path="devices/:id" element={<DeviceInfo />}>
				{isGlobal && (
					<>
						<Route path="configuration" element={<DeviceConfig />} />
						<Route path="destiny" element={<DeviceDestinyConfig />} />
						<Route path="edit" element={<DeviceEdit />} />
						<Route path="box" element={<BoxDeviceConfig />}>
							<Route path="edit" element={<BoxEditConfig />} />
						</Route>
						<Route path="thermo/:optionId" element={<DeviceThermoConfig />} />
					</>
				)}
			</Route>

			<Route path="products" element={<ProductsList />}>
				<Route path=":id/edit" element={<ProductEdit />} />
				<Route path=":id/barcode/:barcode" element={<ProductQrCode />} />

				<Route path="create" element={<NewProduct />} />
				<Route path="delete-selected" element={<DeleteSelectedProducts />} />
				{isGlobal && <Route path="category" element={<CategoryEdit />} />}
			</Route>

			<Route path="vouchers" element={<VouchersList />}>
				<Route path=":id/edit" element={<VoucherEdit />} />
				<Route path="create" element={<NewVoucher />} />
			</Route>

			<Route path="users" element={<UsersList />}>
				<Route path=":id/edit" element={<UsersEdit />} />
			</Route>

			<Route path="shops" element={<ShopsList />}>
				<Route path=":id/edit" element={<ShopEdit />} />
				{isGlobal && <Route path="create" element={<NewShop />} />}
			</Route>

			{/* <Route path="calendar" element={<>calendar</>}></Route> */}

			<Route path="info" element={<InformationList />}></Route>

			{/* <Route path="reports" element={<>reports</>}></Route> */}
		</Route>
	);
};

export default AdminRoutes;
