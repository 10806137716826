import React, { useState } from "react";
import styles from "styles/DeviceConfig.module.css";
import { useFetch } from "hooks/useFetch";
import PathsAPI from "constants/PathsAPI";
import { useNavigate, useLocation } from "react-router-dom";
import Button from "components/Button";
import NavButtonLink from "components/NavButtonLink";
import { useEffect, useRef } from "react";
import Draggable from "react-draggable";
import stateMode from "constants/stateMode";
import { toast } from "react-toastify";

const DeleteSelectedProducts = () => {
	const nodeRef = useRef(null);
	const location = useLocation();

	const { useDelete } = useFetch(PathsAPI.Products);
	const { request, loading } = useDelete();
	const [selectedRows, setSelectedRows] = useState([]);

	let navigate = useNavigate();

	const handleSubmit = async (e) => {
		e.preventDefault();

		const promises = [];

		for (const orderId of selectedRows) {
			promises.push(request({ version: "V2", url: `/${orderId}`, }));
		}

		let responses = {};

		await (
			await Promise.allSettled(promises)
		).forEach((response, index) => {
			if (response.status === "rejected") {
				responses[selectedRows[index]] = response;
				toast.error(
					<>
						<b>ID produktu: {selectedRows[index]}</b>
						<br />
						{response.reason?.message || "Wystąpił problem z usunięciem produktu"}
					</>,
					{ autoClose: false }
				);
			}
		});

		toast.success("Produkty zostały usunięte");

		navigate("../", { state: { mode: stateMode.RefreshWithResponse, responses } });
	};

	useEffect(() => {
		if (location.state?.selectedRows) {
			setSelectedRows(location.state.selectedRows);
		}
	}, [location]);

	return (
		<Draggable nodeRef={nodeRef} handle={`.${styles.header}`}>
			<div ref={nodeRef} className={styles.container}>
				<div className={styles.header}>
					<h2>Usuń zaznaczone produkty</h2>
					<NavButtonLink type="reset" to="../" state={stateMode.Refresh}>
						&#x2715;
					</NavButtonLink>
				</div>
				<form className={styles.form} onSubmit={handleSubmit}>
					<h3>Wybranych wierszy: {selectedRows.length}</h3>

					<div className={styles.buttons}>
						<Button type="submit" loading={loading}>
							Potwierdź usunięcie
						</Button>
					</div>
				</form>
			</div>
		</Draggable>
	);
};

export default DeleteSelectedProducts;
